<template>
  <PageLayout
    :title="$t('common.match')"
    :loading="matching"
  >
    <div
      class="row"
      :key="keyz"
    >
      <div class="col-12">
        <div class="w-25 pl-1">
          <div class="d-inline-block mb-2">
            <span
              class="text-dark-l3 font-medium mb-2"
            >{{ $t("labels.target_percentage_matched") }}:</span>
            <span
              class="text-md font-medium"
            >{{ target_percentage_matched }}%</span>
          </div>

          <!-- <el-slider
            v-model="target_percentage_matched"
            :marks="marks"
          ></el-slider> -->

          <div class="mt-5">
            <el-checkbox
              v-model="isOrganizational"
              :label="$t('common.organizational_matching')"
            />
          </div>
        </div>
        <div
          style="padding-left: 36px"
          v-if="isOrganizational"
        >
          <span class="text-sm text-dark-l2">
            <strong class="text-primary">{{ $t("labels.warning") }}</strong>
            {{ $t("messages.1_month_or_less") }}
          </span>
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div
        class="col-12 d-flex align-items-center mb-4"
        ref="sitesDiv"
      >
        <h2
          class="m-0 font-medium"
          :class="[sitesSelected ? 'text-success' : 'text-dark-l3']"
        >
          {{ $t("placeholders.select_sites_to_match") }}
        </h2>

        <el-button
          size="small"
          type="primary"
          class="ml-auto"
          @click="refreshData"
        >
          {{ $t("labels.refresh_data") }}
        </el-button>
      </div>

      <div
        class="col-12 mb-4"
        v-if="
          ongoingProcess.status &&
            ongoingProcess.percentage_of_completion &&
            (ongoingProcess.status !== 'finished' ||
              ongoingProcess.percentage_of_completion < 100)
        "
      >
        <template v-if="['started', 'pending'].includes(ongoingProcess.status)">
          <el-alert
            type="error"
            :closable="false"
          >
            There is an ongoing process with the status of
            <strong>{{ ongoingProcess.status }}</strong>. {{ ongoingProcess.percentage_of_completion }}% of consumption
            data has been inserted.
          </el-alert>
        </template>

        <template v-else>
          <el-alert
            type="warning"
            :closable="false"
          >
            The consumption data is
            <strong>{{ ongoingProcess.percentage_of_completion }}%</strong>
            filled. Some consumption sites may not have consumption data. Please
            check the logs.
          </el-alert>
        </template>
      </div>

      <div
        class="col-6 col--production-site"
        v-loading="!productionSitesLoaded"
      >
        <div>
          {{
            $t("placeholders.select_sth", {
              msg: $tc("common.production_site", 1),
            })
          }}
          <el-select
            class="mt-2"
            v-model="selectedPS"
            placeholder="Select"
            filterable
            @change="fetchProductionData(selectedPS)"
            :loading="!productionSitesLoaded"
            data-cy="select-production-sites"
          >
            <el-option
              v-for="productionSite in productionSites"
              :key="productionSite.id"
              :label="productionSite.name"
              :value="productionSite.id"
              :data-cy="`${productionSite.name}`"
            >
              <div class="py-2">
                <span class="d-block m-0 mb-2 font-medium">
                  {{ productionSite.name }}
                  <span class="font-normal">{{
                    `[${productionSite.energy_source}]`
                  }}</span>
                </span>
                <span class="d-block m-0 text-xs">{{
                  `${
                    productionSite.location.city &&
                    productionSite.location.city + ", "
                  } ${productionSite.location.country}`
                }}</span>
              </div>
            </el-option>
          </el-select>
        </div>
      </div>

      <div
        class="col-6 col--consumption-site"
        v-loading="!consumptionSitesLoaded || !consumersLoaded"
      >
        <div v-if="!isOrganizational">
          {{
            $t("placeholders.select_sth", {
              msg: $tc("common.consumption_site", 1),
            })
          }}
          <el-select
            class="mt-2"
            v-model="selectedCS"
            placeholder="Select"
            filterable
            @change="fetchConsumptionData(selectedCS)"
            :loading="!consumptionSitesLoaded"
            data-cy="select-consumption-sites"
          >
            <el-option
              v-for="consumptionSite in consumptionSites"
              :key="consumptionSite.id"
              :label="consumptionSite.name"
              :value="consumptionSite.id"
              :data-cy="`${consumptionSite.name}`"
            >
              <div class="py-2">
                <span class="d-block m-0 mb-2 font-medium">{{
                  consumptionSite.name
                }}</span>
                <span class="d-block m-0 text-xs">{{
                  `${
                    consumptionSite.location.city &&
                    consumptionSite.location.city + ", "
                  } ${consumptionSite.location.country}`
                }}</span>
              </div>
            </el-option>
          </el-select>
        </div>

        <div v-else>
          {{
            $t("placeholders.select_sth", { msg: $tc("common.consumer", 1) })
          }}
          <el-select
            class="mt-2"
            v-model="selectedConsumer"
            placeholder="Select"
            filterable
            @change="fetchConsumptionData(selectedConsumer)"
            :loading="!consumersLoaded"
          >
            <el-option
              v-for="consumer in consumers"
              :key="consumer.id"
              :label="consumer.name"
              :value="consumer.id"
            >
              <div class="py-2">
                <span class="d-block m-0 mb-2 font-medium">{{
                  consumer.name
                }}</span>
                <span class="d-block m-0 text-xs">{{
                  `${consumer.location.city && consumer.location.city + ", "} ${
                    consumer.location.country
                  }`
                }}</span>
              </div>
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="col-12">
        <div class="sites-wrapper card mt-4">
          <div
            class="heighted ps"
            :class="
              selectedPS && Object.keys(productionData).length ? 'bg-white' : ''
            "
            v-loading="selectedPS && !productionDataLoaded"
          >
            <template v-if="selectedPS && Object.keys(productionData).length">
              <h3 class="mt-0 mb-1 font-medium truncate">
                {{ selectedPSDetails.name }}
              </h3>
              <div class="text-sm">
                <p class="m-0 truncate">
                  {{
                    `${formattedAddress(selectedPSDetails.location, "short")}`
                  }}
                  <!-- {{ `• ${selectedPSDetails.energy_source}` }} -->
                </p>
              </div>

              <div class="divider"></div>

              <div class="pt-2">
                <AggregatedChart
                  :production-data="productionDataSorted"
                  :source-type="selectedPSDetails.energy_source"
                  chart-type="production"
                />
              </div>
            </template>
          </div>

          <div
            class="heighted cs"
            :class="
              selectedCS && Object.keys(consumptionData).length
                ? 'bg-white'
                : ''
            "
            v-loading="selectedCS && !consumptionDataLoaded"
            v-if="!isOrganizational"
          >
            <template v-if="selectedCS && Object.keys(consumptionData).length">
              <h3 class="mt-0 mb-1 font-medium truncate">
                {{ selectedCSDetails.name }}
              </h3>

              <div class="text-sm">
                <p class="m-0 truncate">
                  {{
                    `${formattedAddress(selectedCSDetails.location, "short")}`
                  }}
                </p>
              </div>

              <div class="divider"></div>

              <div class="pt-2">
                <AggregatedChart
                  :consumption-data="consumptionDataSorted"
                  chart-type="consumption"
                />
              </div>
            </template>
          </div>

          <div
            class="heighted cs"
            :class="
              selectedConsumer && Object.keys(consumptionData).length
                ? 'bg-white'
                : ''
            "
            v-loading="selectedConsumer && !consumptionDataLoaded"
            v-else
          >
            <template
              v-if="selectedConsumer && Object.keys(consumptionData).length"
            >
              <h3 class="mt-0 mb-1 font-medium truncate">
                {{ selectedConsumerDetails.name }}
              </h3>

              <div class="text-sm">
                <p class="m-0 truncate">
                  {{
                    `${formattedAddress(
                      selectedConsumerDetails.location,
                      "short"
                    )}`
                  }}
                </p>
              </div>

              <div class="divider"></div>

              <div class="pt-2">
                <AggregatedChart
                  :consumption-data="consumptionDataSorted"
                  chart-type="consumption"
                />
              </div>
            </template>
          </div>

          <div class="match-button-wrapper">
            <el-button
              class="match-button"
              :class="disableButton || !sitesSelected ? '' : 'pulse'"
              type="primary"
              :disabled="disableButton || !sitesSelected || isRequesting"
              :loading="disableButton || !sitesSelected || isRequesting"
              @click="matchSites"
              data-cy="match-button"
            >
              <p class="d-flex flex-column">
                <span class="d-block"> {{ $t("common.match") }}</span>
              </p>
            </el-button>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      :visible.sync="matchRequestAlert"
      :title="$t('labels.warning')"
    >
      <div
        class="match-request-list mb-4"
        v-if="
          Object.keys(matchRequestList).length &&
            selectedPS &&
            (selectedCS || selectedConsumer)
        "
      >
        <p class="mt-0">
          A match request within these two entities exists and its status is
          <strong
            :class="
              matchRequestList.status === 'finished' ? 'text-success' : ''
            "
          >{{ matchRequestList.status }}</strong>. Are you sure you still want to continue?
        </p>
        <el-alert
          type="info"
          title="Last Request Attempt"
          :closable="false"
        >
          <div>
            <span class="block font-normal mt-0 mb-4">
              {{
                `${matchRequestList.production_site.name} -> ${
                  isOrganizational
                    ? matchRequestList.consumer.name
                    : matchRequestList.consumption_site.name
                }`
              }}
              <span
                class="font-semibold"
              >({{ matchRequestList.match_type }})</span>
            </span>

            <div class="mt-2 pl-3">
              <table class="table">
                <tbody>
                  <tr>
                    <td class="py-1 pr-2 font-medium">
                      Request ID:
                    </td>
                    <td class="py-1 pr-2">
                      {{ matchRequestList.id }}
                    </td>
                  </tr>
                  <tr>
                    <td class="py-1 pr-2 font-medium">
                      Status:
                    </td>
                    <td class="py-1 pr-2 text-uppercase font-semibold">
                      {{ matchRequestList.status }}
                    </td>
                  </tr>
                  <tr>
                    <td class="py-1 pr-2 font-medium">
                      Target Percentage Matched:
                    </td>
                    <td class="py-1 pr-2 text-uppercase">
                      {{ matchRequestList.target_percentage_matched }}%
                    </td>
                  </tr>
                  <tr v-if="matchRequestList.status === 'finished'">
                    <td class="py-1 pr-2 font-medium">
                      Overall Matched Percentage:
                    </td>
                    <td class="py-1 pr-2 text-uppercase">
                      {{ matchRequestList.overall_matched_percentage }}%
                    </td>
                  </tr>
                  <tr v-if="matchRequestList.status === 'started'">
                    <td class="py-1 pr-2 font-medium">
                      Progress:
                    </td>
                    <td class="py-1 pr-2">
                      {{ matchRequestList.progress_percentage }}%
                      <TheLoader class="ml-3" />
                    </td>
                  </tr>
                  <tr>
                    <td class="py-1 pr-2 font-medium">
                      Start - End Time:
                    </td>
                    <td class="py-1 pr-2">
                      {{ formatDate(matchRequestList.start_time) }} -
                      {{ formatDate(matchRequestList.end_time) }}
                    </td>
                  </tr>
                  <tr>
                    <td class="py-1 pr-2 font-medium">
                      Created at:
                    </td>
                    <td class="py-1 pr-2">
                      {{ formatDate(matchRequestList.created_at) }} ({{
                        timeAgo(matchRequestList.created_at)
                      }})
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </el-alert>
      </div>

      <div v-else>
        Updating...
      </div>

      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="matchRequestAlert = false">
          {{ $t("labels.cancel") }}
        </el-button>
        <el-button
          type="primary"
          @click="requestMatch"
          :disabled="isRequesting"
          :loading="isRequesting"
        >
          {{ $t("labels.confirm") }}
        </el-button>
      </span>
    </el-dialog>
  </PageLayout>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import axios from "axios";
import dayjs from "dayjs";
import { getProductionSitesList } from "@/api/energy-assets/production_site";
import { getConsumptionSitesList } from "@/api/energy-assets/consumption_site";
import { getProductionDataList } from "@/api/energy-data/production_data";
import { getConsumptionDataList } from "@/api/energy-data/consumption_data";
import { dynamicSort, seperateThousand, slugify } from "@/utils";
import { formattedAddress } from "@/helpers/formats";
import { getConsumersList } from "@/api/organizations/consumer";
import { getMatchRequest } from "@/api/energy-data/match_data";
import { formatDate, timeAgo } from "@/plugins/dayjs";
import { debounce } from "lodash";
import { getImportDataRequest } from "@/api/energy-data/import_data";

export default defineComponent({
  name: "Match",
  components: {
    AggregatedChart: () => import("@/components/charts/AggregatedChart.vue"),
    TheLoader: () => import("@/components/TheLoader.vue"),
  },
  data() {
    return {
      keyz: 0,

      productionSites: [],
      productionSitesLoaded: false,
      selectedPS: null,
      productionData: {},
      productionDataLoaded: false,

      consumptionSites: [],
      consumptionSitesLoaded: false,
      selectedCS: null,
      consumptionData: {},
      consumptionDataLoaded: false,

      consumers: [],
      consumersLoaded: false,
      selectedConsumer: null,

      ongoingProcess: {},

      sitesSelected: false,
      matching: false,

      target_percentage_matched: 100,
      marks: {
        0: "0%",
        25: "25%",
        50: "50%",
        75: "75%",
        100: "100%",
      },

      siteFilter: "",
      disableButton: false,
      isOrganizational: true,

      matchRequestList: {},
      matchRequestAlert: false,
      isRequesting: false,
    };
  },
  computed: {
    matchData() {
      return {
        start_time: this.$store.state.period[0],
        end_time: this.$store.state.period[1],
        production_site: this.selectedPS,
        consumption_site: this.selectedCS,
        consumer: this.selectedConsumer,
        target_percentage_matched: this.target_percentage_matched,
      };
    },
    productionDataSorted() {
      const data = [...this.productionData.hourly_aggregated_data];
      return data.sort(dynamicSort("hour"));
    },

    /* Custom-Filtered Production Sites List */
    filteredProductionSites() {
      if (this.siteFilter.length) {
        return this.productionSites.filter(site => {
          const siteName = slugify(site.name);
          const filteredName = slugify(this.siteFilter);

          return siteName.includes(filteredName);
        });
      } else {
        return this.productionSites;
      }
    },
    /* Custom-Filtered Consumption Sites List */
    filteredConsumptionSites() {
      if (this.siteFilter.length) {
        return this.consumptionSites.filter(site => {
          const siteName = slugify(site.name);
          const filteredName = slugify(this.siteFilter);

          return siteName.includes(filteredName);
        });
      } else {
        return this.consumptionSites;
      }
    },

    consumptionDataSorted() {
      const data = [...this.consumptionData.hourly_aggregated_data];
      return data.sort(dynamicSort("hour"));
    },

    consumptionDataFiltered() {
      return this.consumptionDataSorted.filter(
        data => data.total_amount_consumed > 0
      );
    },

    selectedPSDetails() {
      return this.productionSites.filter(
        obj => obj.id === this.selectedPS
      )[0];
    },
    selectedCSDetails() {
      return this.consumptionSites.filter(
        obj => obj.id === this.selectedCS
      )[0];
    },
    selectedConsumerDetails() {
      return this.consumers.filter(
        obj => obj.id === this.selectedConsumer
      )[0];
    },
    totalConsumptionMatched() {
      return (
        this.consumptionData.total_amount_matched_solar +
        this.consumptionData.total_amount_matched_wind +
        this.consumptionData.total_amount_matched_hydro +
        this.consumptionData.total_amount_matched_marine +
        this.consumptionData.total_amount_matched_thermal +
        this.consumptionData.total_amount_matched_solid +
        this.consumptionData.total_amount_matched_liquid +
        this.consumptionData.total_amount_matched_gaseous
      );
    },
  },
  watch: {
    isOrganizational(bool) {
      this.$store.dispatch("setOrganizationalMatching", bool);

      if (bool === true) {
        if (
          dayjs(this.matchData.end_time).diff(
            dayjs(this.matchData.start_time),
            "day"
          ) > 31
        ) {
          const format = "YYYY-MM-DDT00:00:00+03:00";
          this.matchData.end_time = dayjs(this.matchData.start_time)
            .add(30, "day")
            .format(format);
          this.$store.dispatch("setCurrentPeriod", [
            this.matchData.start_time,
            this.matchData.end_time,
          ]);
        }
        this.selectedCS = "";
        if (this.selectedConsumer) {
          this.fetchConsumptionData(this.selectedConsumer);
        }
      } else {
        this.selectedConsumer = "";
        if (this.selectedCS) this.fetchConsumptionData(this.selectedCS);
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      await vm.fetchAll();
      if (from.name === "ConsumptionSite" && from.params.id !== undefined) {
        vm.selectedCS = from.params.id;
        vm.fetchConsumptionData(vm.selectedCS);
      } else if (
        from.name === "ProductionSite" &&
        from.params.id !== undefined
      ) {
        vm.selectedPS = from.params.id;
        vm.fetchProductionData(vm.selectedPS);
      } else if (from.name === "Consumer" && from.params.id !== undefined) {
        vm.isOrganizational = true;
        vm.selectedConsumer = from.params.id;
        vm.fetchConsumptionData(vm.selectedPS);
      } else {
        next();
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("setOrganizationalMatching", false);
    next();
  },
  async beforeMount() {
    const arr = window.location.search && window.location.search.split("&");
    const tar =
      typeof arr === "object" &&
      arr.length === 3 &&
      arr.find(item => item.includes("target")).split("=")[1];

    if (tar) this.target_percentage_matched = Number(tar);
  },
  async mounted() {
    this.fetchAll();

    this.$watch(
      () => [this.selectedPS, this.selectedCS, this.selectedConsumer],
      async ([newSelectedPS, newSelectedCS, newSelectedConsumer]) => {
        if (newSelectedPS && (newSelectedCS || newSelectedConsumer)) {
          this.sitesSelected = true;
          this.fetchMatchRequestList();
        } else {
          this.sitesSelected = false;
        }

        this.fetchOngoingProcess();
      },
      { deep: true }
    );

    /**
     ** Set query string for URL
     **/
    this.$watch(
      () => this.$store.state.period,
      (newVal, oldVal) => {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.fetchAll();
        }

        if (this.selectedConsumer) {
          this.fetchOngoingProcess();
        }

        this.setQuery();
      },
      { deep: true, immediate: true }
    );

    /**
     ** Update query string URL for target percentage matched
     **/
    this.$watch(
      () => this.target_percentage_matched,
      debounce((newVal, oldVal) => {
        if (newVal !== oldVal) {
          this.setQuery();
        }
      }, 250)
    );
  },
  methods: {
    setQuery() {
      this.$router
        .replace({
          query: Object.assign(
            {},
            {
              start: this.$store.state.period[0].split("T")[0],
              end: this.$store.state.period[1].split("T")[0],
              target: this.target_percentage_matched,
            }
          ),
        })
        .catch(() => {});
    },

    async fetchOngoingProcess() {
      this.ongoingProcess = {};

      if (this.selectedConsumer) {
        const { results } = await getImportDataRequest({
          consumer: this.selectedConsumer,
          consumption_start: this.$store.state.period[0].split("T")[0],
        });

        this.ongoingProcess = {
          percentage_of_completion: results?.[0]?.percentage_of_completion,
          status: results?.[0]?.status,
        };
      }
    },

    refreshData() {
      if (
        (this.selectedCS !== null && this.selectedCS.length) ||
        (this.selectedConsumer !== null && this.selectedConsumer.length)
      ) {
        this.fetchConsumptionData();
      }

      if (this.selectedPS !== null && this.selectedPS.length) {
        this.fetchProductionData(this.selectedPS);
      }

      if (
        this.selectedPS !== null &&
        this.selectedPS.length &&
        ((this.selectedCS !== null && this.selectedCS.length) ||
          (this.selectedConsumer !== null && this.selectedConsumer.length))
      ) {
        this.fetchMatchRequestList();
      }

      this.fetchOngoingProcess();
    },

    async requestMatch() {
      const h = this.$createElement;
      const dataSite = { ...this.matchData };
      const dataConsumer = { ...this.matchData };

      this.isRequesting = true;
      this.disableButton = true;

      window.setTimeout(() => {
        this.disableButton = false;
      }, 3000);

      delete dataSite.consumer;
      delete dataConsumer.consumption_site;

      await axios({
        method: "post",
        baseURL: `${
          process.env.VUE_APP_API_PATH
        }/energy-data/matched-energy-data/${
          this.isOrganizational ? "match-consumer/" : "match-site/"
        }`,
        data: this.isOrganizational ? dataConsumer : dataSite,
      })
        .then(res => {
          this.$alert(
            h("p", null, [
              h("span", { class: "d-block mb-2" }, res.data.detail),
              h("pre", ["Request ID: ", h("code", res.data.id)]),
            ]),
            "Success",
            {
              confirmButtonText: "OK",
              type: "success",
              callback: () => {
                this.refreshData();
                this.matchRequestAlert = false;
              },
            }
          );
        })
        .catch(err => {
          this.$message({
            message: err.response.data.errors.custom[0],
            center: true,
            type: "error",
          });
        })
        .finally(() => {
          this.isRequesting = false;
        });
    },

    async matchSites() {
      // this.matching = true;
      if (
        Object.keys(this.matchRequestList)
          .length /* && this.matchRequestList.status !== "finished" */
      ) {
        await this.refreshData();
        this.matchRequestAlert = true;
        return;
      } else {
        this.requestMatch();
      }
    },

    async fetchMatchRequestList() {
      const opts =
        {}; /* as { productionSite?: string; consumptionSite?: String; consumer?: string; } */
      this.matchRequestList = {};

      if (this.selectedPS && this.selectedPS.length) {
        opts.productionSite = this.selectedPS;
      }
      if (this.selectedCS && this.selectedCS.length) {
        opts.consumptionSite = this.selectedCS;
      }
      if (this.selectedConsumer && this.selectedConsumer.length) {
        opts.consumer = this.selectedConsumer;
        opts.matchType = ["company-wide"];
      }

      await getMatchRequest({
        ...opts,
        orderBy: ["-created_at"],
        // startTime: this.$store.state.period[0],
        // endTime: this.$store.state.period[1],
      })
        .then(res => {
          if (res.results.length) {
            [this.matchRequestList] = res.results;
          } else {
            this.matchRequestList = {};
          }
        })
        .catch(err => {
          alert(err.errors.detail);
        });
    },

    async fetchProductionSites() {
      this.productionSitesLoaded = false;

      await getProductionSitesList({
        limit: Number.MAX_SAFE_INTEGER,
        offset: 0,
      })
        .then(res => {
          if (res.results) {
            this.productionSites = res.results.sort(dynamicSort("-created_at"));
          }

          this.productionSitesLoaded = true;
        })
        .catch(() => {
          console.error("Match.vue getProductionSitesList");

          this.productionSitesLoaded = false;
        });
    },
    async fetchConsumptionSites() {
      this.consumptionSitesLoaded = false;

      await getConsumptionSitesList({
        limit: Number.MAX_SAFE_INTEGER,
        offset: 0,
      })
        .then(res => {
          if (res.results) {
            this.consumptionSites = res.results.sort(
              dynamicSort("-created_at")
            );
          }

          this.consumptionSitesLoaded = true;
        })
        .catch(() => {
          // alert(JSON.stringify(err.body));
          console.error("Match.vue fetchConsumptionSites");
          this.consumptionSitesLoaded = false;
        });
    },
    async fetchConsumers() {
      await getConsumersList({
        limit: Number.MAX_SAFE_INTEGER,
        offset: 0,
      })
        .then(res => {
          if (res.results) {
            this.consumers = res.results.sort(dynamicSort("-created_at"));
          }

          this.consumersLoaded = true;
        })
        .catch(() => {
          // alert(JSON.stringify(err.body));
          console.error("Match.vue fetchConsumers");
          this.consumersLoaded = false;
        });
    },

    async fetchProductionData(id) {
      this.productionData = {};
      this.productionDataLoaded = false;

      await getProductionDataList({
        productionSite: id,
        productionStartAfter: this.$store.state.period[0],
        productionStartBefore: this.$store.state.period[1],
        limit: 0,
        offset: 0,
      })
        .then(res => {
          this.productionData = res;
          this.productionDataLoaded = true;

          if (this.productionData.results) {
            this.productionData.results.sort(dynamicSort("production_start"));
          }
        })
        .catch(() => {
          this.productionDataLoaded = false;
          // alert(JSON.stringify(err.body));
          console.error("Match.vue fetchProductionData");
        });
    },

    async fetchConsumptionData() {
      this.consumptionData = {};
      this.consumptionDataLoaded = false;

      const requestData = {
        consumptionStartAfter: this.$store.state.period[0],
        consumptionStartBefore: this.$store.state.period[1],
        limit: 0,
        offset: 0,
      };

      if (this.isOrganizational) requestData.consumer = this.selectedConsumer;
      else requestData.consumptionSite = this.selectedCS;

      await getConsumptionDataList({
        ...requestData,
      })
        .then(res => {
          this.consumptionData = res;
          this.consumptionDataLoaded = true;

          if (this.consumptionData.results) {
            this.consumptionData.results.sort(dynamicSort("consumption_start"));
          }
        })
        .catch(() => {
          this.consumptionDataLoaded = false;
          // alert(JSON.stringify(err.body));
          console.error("Match.vue fetchConsumptionData");
        });
    },

    async fetchAll() {
      if (!this.productionSitesLoaded) await this.fetchProductionSites();
      if (!this.consumptionSitesLoaded) await this.fetchConsumptionSites();
      if (!this.consumersLoaded) await this.fetchConsumers();
      if (this.selectedPS) this.fetchProductionData(this.selectedPS);
      if (this.selectedCS || this.selectedConsumer) this.fetchConsumptionData();
    },
  },
  setup() {
    return { formattedAddress, formatDate, timeAgo, seperateThousand };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/abstracts/_variables.scss";

$num: 245;

.el-select-dropdown__item {
  height: auto !important;
  line-height: 1.5 !important;

  &:not(:first-child) {
    margin-top: -1px;
    border-top: 1px solid rgb($num, $num, $num);
  }

  &:not(:last-child) {
    border-bottom: 1px solid rgb($num, $num, $num);
  }
}

.sites-wrapper {
  min-height: 540px;
  background: $field;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    width: 1px;
    height: 100%;
    border: 1px dashed $border;
    transform: translateX(-50%);
  }

  .theme--dark & {
    background: $dark !important;

    &::after {
      border-color: $dark-l3;
    }
  }
}

.heighted {
  width: 50%;
  height: 100%;
  min-height: 540px;
  border-radius: 6px;
  padding: 2rem 3rem;
  border: 0;

  .theme--dark &.bg-white {
    background: $dark-l1 !important;
  }

  &.ps {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.cs {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.match-button {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  width: 256px;

  &.pulse {
    box-shadow: 0 0 0 0 rgba($primary, 1);
    animation: pulse-primary 2s infinite;
  }

  &-wrapper {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -100%);
    z-index: 1000;
  }
}

table.site-details {
  border-collapse: collapse;
  width: 100%;

  th {
    padding: 0.375rem 0.25rem;
    font-weight: 543;
    text-align: left;
  }

  td {
    padding: 0.375rem 0.25rem;
    padding-left: 0.75rem;

    &:last-child {
      text-align: right;
    }
  }

  tfoot {
    box-sizing: border-box;
    border-top: 1px solid $border;
  }
}

table.match-details {
  border-collapse: collapse;
  width: 100%;
  text-align: center;

  th {
    font-weight: 543;
  }

  th,
  td {
    padding: 0.375rem 0.25rem;
    width: calc(100% / 3);

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }

  tfoot {
    box-sizing: border-box;
    border-top: 1px solid $border;
  }
}
</style>
