import axios from "axios";
import { IListResponse } from "@/lib/types/base";
import { IImportData, IImportedEnergyData } from "@/lib/types/energy-data/import_data";

export async function getImportDataRequest(params: { order_by: string; limit: number; offset?: number; }) {
  return new Promise<IListResponse<IImportData>>((resolve, reject) => {
    axios.get(process.env.VUE_APP_API_PATH + "/energy-data/import-request", { params })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export async function getImportedEnergyData(
  params: {
    consumption_request?: string;
    consumption_site?: string;
    completed?: boolean;
  } & {
    [key: string]: any
  },
  signal?: AbortController["signal"]
) {
  return new Promise<IListResponse<IImportedEnergyData>>((resolve, reject) => {
    axios.get(process.env.VUE_APP_API_PATH + "/energy-data/imported-energy-data", { params, signal })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}
